import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "gatsby-image"

import Layout from "./layout"
import SEO from "./seo"

import styles from "../styles/insights-page.module.css"

function PageTemplate({ data: { mdx } }) {
  let metaHeaderCategoryClass = () => {
    switch (mdx.frontmatter.category) {
      case "News":
        return "metaHeaderNewsCategoryClass"
      case "Technology":
        return "metaHeaderTechnologyCategoryClass"
      case "Email Marketing":
        return "metaHeaderEmailMarketingCaregoryClass"
      case "Inside Poirot":
        return "metaHeaderInsidePoirotCategoryClass"
      default:
        return "metaHeaderUnknownCategoryClass"
    }
  }

  return (
    <Layout isNavigationTransparent={false} isHomePage={false}>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        keywords={mdx.frontmatter.keywords}
      />

      <div className={styles.insightsPostWrapper}>
        <div className="sectionWrapper1200">
          <div className={styles.insightsPost}>
            <div className={styles.postWrapperMetaDataHeader}>
              <div>
                <span className={metaHeaderCategoryClass()}>
                  {mdx.frontmatter.category}
                </span>
              </div>
              <div>
                <span className={styles.metaHeaderDate}>
                  {mdx.frontmatter.date}
                </span>
              </div>
            </div>
            <h1 className={styles.title}>{mdx.frontmatter.title}</h1>
            <h2 className={styles.description}>
              {mdx.frontmatter.description}
            </h2>
            <Img fluid={mdx.frontmatter.image.childImageSharp.fluid} />
            <MDXRenderer>{mdx.body}</MDXRenderer>
            <div className={styles.socialShareWrapper}>
              <div>
                <a
                  href={`https://twitter.com/intent/tweet/?text=${mdx.frontmatter.title}&url=https://poirot.app${mdx.fields.slug}&via=PoirotApp`}
                  target="_blank"
                >
                  <svg className={styles.twitterShareButton}
                    height="20"
                    viewBox="0 0 61 20"
                    width="61"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g transform="translate(-150 -97)">
                      <path className={styles.twitterShareButtonBackground}
                        d="m153 97h55c1.657 0 3 1.343 3 3v14c0 1.657-1.343 3-3 3h-55c-1.657 0-3-1.343-3-3v-14c0-1.657 1.343-3 3-3z"
                        fill="#1b95e0"
                      />
                      <g fill="#fff">
                        <path d="m176.088 102.646h6.358v1.188h-2.497v6.666h-1.375v-6.666h-2.486z" />
                        <path d="m181.445 104.813h1.331l1.111 4.246h.022l1.067-4.246h1.265l1.023 4.246h.022l1.155-4.246h1.276l-1.782 5.687h-1.287l-1.056-4.224h-.022l-1.045 4.224h-1.32z" />
                        <path d="m194.425 107.156q-.022-.297-.127-.572-.104-.275-.286-.479-.181-.203-.44-.329-.258-.127-.577-.127-.33 0-.6.116-.269.115-.462.319-.192.203-.308.478-.115.275-.126.594zm-2.926.825q0 .33.093.638.094.308.281.539.187.231.473.369.286.137.682.137.55 0 .885-.236.336-.237.501-.71h1.188q-.099.462-.341.825-.242.363-.583.611-.341.247-.765.374-.423.126-.885.126-.671 0-1.188-.22-.517-.22-.875-.616-.357-.396-.539-.946-.181-.55-.181-1.21 0-.605.192-1.149.193-.545.55-.957.358-.413.864-.655.506-.242 1.144-.242.671 0 1.204.281.534.28.886.742.352.462.511 1.061.16.6.083 1.238z" />
                        <path d="m200.541 107.156q-.022-.297-.127-.572-.104-.275-.286-.479-.181-.203-.44-.329-.258-.127-.577-.127-.33 0-.6.116-.269.115-.462.319-.192.203-.307.478-.116.275-.127.594zm-2.926.825q0 .33.093.638.094.308.281.539.187.231.473.369.286.137.682.137.55 0 .885-.236.336-.237.501-.71h1.188q-.099.462-.341.825-.242.363-.583.611-.341.247-.765.374-.423.126-.885.126-.671 0-1.188-.22-.517-.22-.875-.616-.357-.396-.539-.946-.181-.55-.181-1.21 0-.605.192-1.149.193-.545.551-.957.357-.413.863-.655.506-.242 1.144-.242.671 0 1.204.281.534.28.886.742.352.462.511 1.061.16.6.083 1.238z" />
                        <path d="m202.18 104.813h.946v-1.705h1.254v1.705h1.133v.935h-1.133v3.036q0 .198.016.341.017.143.077.242.061.099.182.149.121.049.33.049.132 0 .264-.005.132-.006.264-.039v.968q-.209.022-.407.044-.198.022-.407.022-.495 0-.797-.093-.303-.094-.474-.275-.17-.182-.231-.457-.06-.275-.071-.627v-3.355h-.946z" />
                        <path d="m161.418 114c6.502 0 10.058-5.387 10.058-10.058 0-.153 0-.305-.01-.457.692-.5 1.289-1.12 1.764-1.83-.646.286-1.33.474-2.031.557.738-.442 1.29-1.137 1.554-1.956-.694.412-1.453.702-2.244.858-1.096-1.165-2.837-1.45-4.247-.695-1.411.754-2.139 2.361-1.777 3.919-2.843-.142-5.491-1.485-7.286-3.693-.938 1.615-.459 3.682 1.095 4.719-.563-.017-1.113-.169-1.605-.443v.045c.001 1.683 1.187 3.132 2.836 3.465-.52.142-1.066.163-1.596.061.463 1.44 1.79 2.427 3.303 2.455-1.252.984-2.798 1.518-4.391 1.516-.281 0-.562-.017-.841-.051 1.617 1.038 3.498 1.588 5.418 1.585" />
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
              <div>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://poirot.app${mdx.fields.slug}`}
                  target="_blank"
                >
                  <svg className={styles.facebookShareButton}
                    height="20"
                    viewBox="0 0 67 20"
                    width="67"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <clipPath id="a">
                      <path d="m157 71.18h14v14h-14z" />
                    </clipPath>
                    <g transform="translate(-150 -68.18)">
                      <path className={styles.facebookShareButtonBackground}
                        d="m153.004 68.18h60.992c1.659 0 3.004 1.343 3.004 3v14c0 1.657-1.345 3-3.004 3h-60.992c-1.659 0-3.004-1.343-3.004-3v-14c0-1.657 1.345-3 3.004-3z"
                        fill="#1877f2"
                      />
                      <g clip-path="url(#a)">
                        <path
                          d="m171 78.18c0-3.866-3.134-7.001-7-7.001-3.867 0-7.001 3.135-7.001 7.001 0 3.494 2.56 6.39 5.907 6.915v-4.892h-1.778v-2.023h1.778v-1.542c0-1.755 1.045-2.724 2.644-2.724.766 0 1.567.137 1.567.137v1.722h-.883c-.869 0-1.141.54-1.141 1.094v1.313h1.942l-.31 2.023h-1.632v4.892c3.347-.525 5.907-3.421 5.907-6.915"
                          fill="#fff"
                        />
                      </g>
                      <g fill="#fff">
                        <path d="m180.536 79.134q.038.672.317 1.091.532.784 1.875.784.601 0 1.095-.172.956-.333.956-1.192 0-.645-.403-.919-.408-.268-1.278-.467l-1.069-.242q-1.047-.236-1.482-.521-.752-.494-.752-1.477 0-1.063.736-1.745.736-.683 2.084-.683 1.24 0 2.108.599.867.599.867 1.915h-1.004q-.081-.634-.344-.972-.489-.618-1.659-.618-.946 0-1.359.398-.414.397-.414.924 0 .58.483.848.317.172 1.435.43l1.106.252q.8.183 1.235.5.752.553.752 1.606 0 1.31-.953 1.874-.953.564-2.216.564-1.471 0-2.304-.752-.832-.746-.816-2.025z" />
                        <path d="m187.046 73.763h.967v2.944q.343-.435.617-.613.468-.306 1.166-.306 1.251 0 1.697.876.242.478.242 1.326v3.69h-.994v-3.625q0-.634-.161-.929-.263-.473-.988-.473-.602 0-1.091.414-.488.413-.488 1.563v3.05h-.967z" />
                        <path d="m193.905 80.15q0 .418.306.66.306.242.725.242.51 0 .988-.236.806-.393.806-1.284v-.779q-.177.113-.457.188-.279.075-.547.107l-.586.076q-.526.069-.789.22-.446.252-.446.806zm2.342-1.956q.333-.043.445-.279.065-.129.065-.37 0-.495-.352-.717-.352-.223-1.007-.223-.757 0-1.074.408-.177.225-.231.671h-.903q.027-1.063.691-1.48.663-.416 1.538-.416 1.016 0 1.649.387.629.387.629 1.203v3.314q0 .15.062.242.061.091.26.091.065 0 .145-.008.081-.008.172-.024v.714q-.226.065-.344.081-.118.016-.322.016-.5 0-.725-.355-.118-.188-.167-.531-.295.386-.848.671-.554.285-1.22.285-.8 0-1.307-.486-.508-.487-.508-1.217 0-.8.5-1.241.499-.44 1.31-.542z" />
                        <path d="m199.308 75.928h.919v.994q.112-.291.553-.707.44-.416 1.015-.416.027 0 .091.005.065.006.22.022v1.02q-.085-.016-.158-.021-.072-.006-.158-.006-.731 0-1.123.47-.392.47-.392 1.083v3.308h-.967z" />
                        <path d="m205.34 75.799q.612 0 1.187.287.575.288.875.744.29.435.387 1.015.086.398.086 1.268h-4.216q.027.875.413 1.404.387.53 1.198.53.757 0 1.209-.5.257-.29.365-.671h.95q-.037.316-.249.706-.212.389-.476.636-.44.43-1.09.58-.349.086-.79.086-1.074 0-1.82-.781-.747-.782-.747-2.189 0-1.386.752-2.25.752-.865 1.966-.865zm1.541 2.546q-.059-.629-.274-1.005-.397-.698-1.326-.698-.666 0-1.117.481-.452.481-.478 1.222z" />
                      </g>
                    </g>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        slug
      }
      frontmatter {
        title
        description
        keywords
        category
        author
        date(formatString: "MMMM Do YYYY")
        image {
          childImageSharp {
            resize(width: 980, height: 540) {
              src
            }
            fluid(maxWidth: 980) {
              src
              srcSet
              aspectRatio
              sizes
              base64
            }
          }
          publicURL
        }
      }
    }
  }
`

export default PageTemplate
